<template>
  <article>
    <h1>오시는 길</h1>
    <ul class="gnb">
      <router-link to="/sub1_a" exact-active-class="active" tag="li">회사소개</router-link>
      <router-link to="/sub1_b" exact-active-class="active" tag="li">연혁</router-link>
      <router-link to="/sub1_c" exact-active-class="active" tag="li">구성원</router-link>
      <router-link to="/sub1_d" exact-active-class="active" tag="li" class="on">오시는 길</router-link>
    </ul>
    <section class="sub1_d">
      <div class="map">
        <div id="daumRoughmapContainer1628730011562" class="root_daum_roughmap root_daum_roughmap_landing"></div>
      </div>                                         
      <h2>네비게이션 : 세종특별자치시 나성로 133-9(NS타워1차) 506호 (주)맵마인드</h2>
      <div class="map_content">
        <div class="bus">
          <div class="bus_icon">
            <img src="/img/bus.png" alt=""><h3>버스</h3>
          </div>
          <div class="bus_all">
            <div class="bus_c1">
              <h4>새롬동, 나성동 정류장 하차 (도보 10분)</h4>
              <p>B1번, B2번, B3번</p>
              <p>601번, 991번, 1000번</p>
            </div>
            <div class="bus_c2">
              <h4>나릿재마을 정류장 하차 (도보 5분)</h4>
              <p>203번</p>
            </div>
          </div>
        </div>
        <div class="car">
          <div class="car_icon">
            <img src="/img/car.png" alt=""><h3>자가용</h3>
          </div>
          <div class="car_all">
            <div class="car_c1">
              <h4>대전역</h4><p>35.17km 소요</p>
            </div>
            <div class="car_c2">
              <h4>세종시외버스터미널</h4><p>3.38km 소요</p>
            </div>
            <div class="car_c3">
              <h4>오송역</h4><p>19.2km 소요</p>
          </div>
          </div>
        </div>
      </div>
    </section>
  </article>
</template>

<!-- 3. 실행 스크립트 -->
<script charset="UTF-8">
  export default {
    mounted(){
      new daum.roughmap.Lander({
        "timestamp" : "1628730011562",
        "key" : "26xtu",
        "mapWidth" : $('.map').width(),
        "mapHeight" : "600"
      }).render();
    }
  }
</script>

